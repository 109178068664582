// ----------------------------------------
// THIRD-PARTY PLUGINS
// ----------------------------------------
//
// All third-party plugins should be renamed with an '_' to prevent seperate file being generated.
//
// The below is a list of commonly used plugins used for many projects. However, you should only
// include the libraries you need for your site. By default, most libraries are excluded.
// To add a third-party library to the project, add a '@' symbol to the 'codekit-prepend' line.
//

// General
// @codekit-prepend "vendor/_lazysizes.js"          // Lazy-loading images
// @codekit-prepend "vendor/_ls.unveilhooks.js"     // BG support for lazy-loading images
// @codekit-prepend "vendor/_flickity.min.js"

// $(document).ready(() => {


// });


// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {
            navActive: false,
            searchActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // create the script element to load
        const googleMapScript = document.createElement('script');
        googleMapScript.type = 'text/javascript';
        googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqMwo3RGRCTcTU4YfZfCD540VhOA76VcI&callback=initMap';
        googleMapScript.setAttribute('defer', '');
        googleMapScript.setAttribute('async', '');
        document.head.appendChild(googleMapScript);

        const mapStyles = [
            {
                featureType: 'administrative',
                elementType: 'all',
                stylers: [
                    {
                        saturation: '-100',
                    },
                ],
            },
            {
                featureType: 'administrative.province',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 65,
                    },
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: '50',
                    },
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: '-100',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                    {
                        lightness: '30',
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                    {
                        lightness: '40',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        hue: '#ffff00',
                    },
                    {
                        lightness: -25,
                    },
                    {
                        saturation: -97,
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                    {
                        lightness: -25,
                    },
                    {
                        saturation: -100,
                    },
                ],
            },
        ];


        // Initialize and add the map
        const initMap = () => {

            const maps = document.querySelectorAll('.js-map');

            maps.forEach((map) => {

                const { locations } = JSON.parse(map.dataset.mapBlock);

                const center = {
                    lat: parseFloat(locations[0].location.lat),
                    lng: parseFloat(locations[0].location.lng),
                };

                console.log(center);

                const gmap = new google.maps.Map(map, {
                    zoom: 16,
                    center,
                    scrollwheel: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    backgroundColor: 'transparent',
                });

                const bounds = new google.maps.LatLngBounds();

                let activeInfoWindow;

                locations.forEach((location) => {

                    const infowindow = new google.maps.InfoWindow({
                        content: `<div><strong>${location.label}</strong></div><div>${location.address}</div>`,
                        ariaLabel: location.label,
                    });

                    const marker = new google.maps.Marker({
                        position: { lat: parseFloat(location.location.lat), lng: parseFloat(location.location.lng) },
                        map: gmap,
                    });
                    bounds.extend(marker.getPosition());

                    marker.addListener('click', () => {
                        if (activeInfoWindow) { activeInfoWindow.close(); }
                        infowindow.open({
                            anchor: marker,
                            map,
                        });
                        activeInfoWindow = infowindow;
                    });
                });

                gmap.fitBounds(bounds); // auto-zoom

            });
        };

        window.initMap = initMap;

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });


        // Fixes initial transitions firing on page load
        // const $body = $('body').removeClass('preload');


        // ----------------------------------------
        // THIRD-PARTY PLUGIN CONFIG
        // ----------------------------------------


        // ----------------------------------------
        // GLOBAL SCRIPTS (header/footer, etc)
        // ----------------------------------------

        // // Simple nav for header
        // $('#js-btn-navpanel').click(() => {
        //     $('body').addClass('navout');
        // });

        // $('#js-btn-close-navpanel').click(() => {
        //     $('body').removeClass('navout');
        // });

        // // Remove nav if body is clicked
        // $body.click(() => {
        //     $body.removeClass('navout');
        // });

        // // Stop propogation if click within the menu.
        // $('#js-navpanel, #js-btn-navpanel').click((event) => {
        //     event.stopPropagation();
        // });

        // // Search
        // const $form = $('.search-form');
        // const $formInput = $form.find('input[type="search"]');

        // $('.btn-search-icon').click(() => {
        //     if ($form.hasClass('shown')) {
        //         $form.submit();
        //     } else {
        //         $form.fadeIn(200);
        //         $form.addClass('shown');

        //         $formInput.focus();
        //     }
        // });

        // $formInput.blur(function() {
        //     const val = $(this).val().trim();

        //     if (val === '') {
        //         $form.fadeOut(200);
        //         $form.removeClass('shown');
        //     }
        // });
    },
}).mount('#app');
